import CryptoJS from 'crypto-js';

const secretKey = 'blog-hex-secretkey';

const toUrlSafeBase64 = (data) => {
  return data.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const fromUrlSafeBase64 = (data) => {
  let padding = '='.repeat((4 - data.length % 4) % 4);
  return data.replace(/-/g, '+').replace(/_/g, '/') + padding;
};

export const encryptData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(data.toString(), secretKey).toString();
    const encodedData = toUrlSafeBase64(encrypted);
    return encodedData;
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};

export const decryptData = (encodedCiphertext) => {
  try {
    
    const ciphertext = fromUrlSafeBase64(encodedCiphertext);
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(decryptedData,"cryptodecryp")
    return decryptedData;
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};
