import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import './service.css'
import { useSelector } from "react-redux";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import Swal from 'sweetalert2'
import { lineSpinner } from 'ldrs';
import { decryptData, encryptData } from "../cryptoutils/cryptoutils";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
lineSpinner.register();


const PaidAccount = () => {
  const{userid,memberid}=useParams()
const userId=decryptData(userid)
const memberId=decryptData(memberid)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selector= useSelector(state=>state)
  const[pending,setpendingamount]=useState(null)
  const[show,setshow]=useState(true)
  const[loading,setloading]=useState(false)
  const[showtable,setshowtable]=useState(true)
  const branchtable = selector.details.data && selector.details.data[""][0].branchtable;
  const[data,setdata]=useState({
       paymentmode:"",
       memberid:"",
       basicpendingamount:"",
       gsttaxamount:"",
       totalpendingamount:"",
       paidamount:"",
       amountinwords:"",
       basicamount:"",
       taxamount:"",
       balanceamount:""
  })

  const[payment,setpayment]=useState([])
  const[pendingdata,setpendingdata]=useState([])
  console.log(memberId,"------------paidmemberid")

   const spa_branch_name=selector.details.data && selector.details.data[""][0].spa_branch_name
   const spa_name=selector.details.data && selector.details.data[""][0].spa_name
   const manager_name=selector.details.data && selector.details.data[""][0].manager_name
  const gstamount= Math.ceil(pending*18/118)
  console.log(gstamount,"gstamount")
  const basictaxamount= pending-gstamount
  console.log(basictaxamount,"basictaxamount")

  
  const getpaymentandtabledata=async()=>{
  const obj={
  query:`[dbo].[spasoftware_web_proc_pendingpayment_account_paymentmode_v2]@memberid='{0}',@userid='{1}'`,
  queryArr:[`${memberId}`,`${userId}`]
 }

 const getdata=await Hexapi(obj)
 console.log(getdata,"paymentmode")

 if(getdata){
  setpayment(getdata.paymentmode || [])
  setpendingdata(getdata.paymentdetails || [])
  setpendingamount(getdata && getdata.cust_bal_amount[0] && getdata.cust_bal_amount[0].cust_bal_amount)
 }
  }

  useEffect(()=>{
   getpaymentandtabledata()
  },[])

function convertNumberToWords(amount) {
  if (isNaN(amount) || amount === 0) {
    return '';
  }
  let words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  let atemp = amount.split(".");
  let number = atemp[0].split(",").join("");
  let n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
          received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
          n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              if (n_array[i] == 1) {
                  n_array[j] = 10 + parseInt(n_array[j]);
                  n_array[i] = 0;
              }
          }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              value = n_array[i] * 10;
          } else {
              value = n_array[i];
          }
          if (value != 0) {
              words_string += words[value] + " ";
          }
          if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Crores ";
          }
          if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Lakhs ";
          }
          if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Thousand ";
          }
          if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
              words_string += "Hundred and ";
          } else if (i == 6 && value != 0) {
              words_string += "Hundred ";
          }
      }
      words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}

function formatDate(dateString) {
  let date = new Date(dateString);

  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

const handlechangepaidamount=(e)=>{
  const paiddata = e.target.value;
  const paidvalue = parseInt(paiddata);
  const wordsamount = convertNumberToWords(paidvalue);
  const tax_amount= paiddata*18/100
  const basic_amount=paiddata-tax_amount

 const balanceamount=pending-paiddata
  setdata((prev) => ({
      ...prev,
      paidamount:paiddata,
      taxamount:Math.round(tax_amount),
      basicamount:Math.round(basic_amount),
      balanceamount:Math.round(balanceamount),
      amountinwords:wordsamount
  }));
  if (isNaN(paidvalue)) {
    setdata((prev) => ({
      ...prev,
      paidAmount: '',
      taxAmount: '',
      basicAmount: '',
      balanceAmount: '',
      amountInWords: ''
    }));
    return;
  }
}

const handleChangePaymentMode = (e) => {
  console.log(e.target.value)
  setdata((prev) => ({
    ...prev,
    paymentmode: e.target.value
  }));
};
const handlesubmit=async(e)=>{
  e.preventDefault()
  if(data.paymentmode ==""){
    Swal.fire({
    })
  }
const obj={
  query:` [dbo].[spasoftware_web_proc_pending_payment_account_insertdata]@managername='{0}',@spaname='{1}',@spa_branch_name='{2}',@service_id='{3}',@payment_mode='{4}',@word='{5}',@paid_amount='{6}',@pending_amount='{7}',@basic_amount='{8}',@gsttaxamount='{9}',@balance_amount='{10}'`
  ,queryArr:[`${manager_name}`,`${spa_name}`,`${spa_branch_name}`,`${userId}`,`${data.paymentmode}`,`${data.amountinwords}`,`${data.paidamount}`,`${pending}`,`${data.basicamount}`,`${data.taxamount}`,`${data.balanceamount}`]
}

  const getpost = await Hexapi(obj);
  console.log(getpost, "pending amount");
  if(getpost){
    try {
      Swal.fire({
        text: `${getpost[""][0]['msg']}`,
        icon: 'ok',
        customClass: {
            popup: 'my-custom-swal',
            title: 'my-custom-swal-title',
            content: 'my-custom-swal-content',
            confirmButton: 'my-custom-swal-confirm',
            cancelButton: 'my-custom-swal-cancel'
        },
    });
    setdata({
      paymentmode:"",
      memberid:"",
      basicpendingamount:"",
      gsttaxamount:"",
      totalpendingamount:"",
      paidamount:"",
      amountinwords:"",
      basicamount:"",
      taxamount:"",
      balanceamount:""
    })
    getpaymentandtabledata()
    } catch (error) {
      Swal.fire({
        text: "error",
        icon: 'ok',
        customClass: {
            popup: 'my-custom-swal',
            title: 'my-custom-swal-title',
            cancelButton: 'my-custom-swal-cancel'
        },
    });
    }
  }
}

return (
  <div>
 { loading ? <div className="text-center mt-5"><l-line-spinner
  size="40"
  stroke="3"
  speed="1"
  color="black"
  className="relative"

></l-line-spinner></div> :

<div className="container top-acc highlightBcg">
    <div className="row ">
        <div className="dashservice">

          <p className="name-text">
          PENDING PAYMENT ACCOUNT
          </p>
          <div className="">
            <FaChevronDown onClick={()=>{setshow(!show)}} className="hideShowBtn" />
          </div >
        </div>
        </div>

      <div className="row bg-body-tertiary">
      {show && (
                  <form onSubmit={handlesubmit} className="form-data">
                    <div className="controls" >
  <div className="control-group">
        <label className="control-label">
                Payment Mode
              </label>
              <div className="controls">
                <select
                  data-placeholder="Select Payment Mode"
                  name="paymentmode"
                  id="payment_mode"
                  className="span6  popovers"

                  onChange={handleChangePaymentMode}
                >
                    <option value="">Select payment mode</option>
                    {payment.map((pay, index) => (
                      <option key={index} value={pay.paymentmode}>{pay.paymentmode}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                MemberId
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span7  popovers"
                  name="memberid"
                  value={memberId}
                  id="customer_name"
                 disabled
                />
              </div>     
            </div>

            <div className="control-group">
            <label className="control-label">
               Basic Pending Amount
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span7  popovers"
                  name="customer_address"
                  id="customer_address"
      value={basictaxamount}
                disabled
                />
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
                GST Tax Amount
              </label>
              <div className="controls">
                <input
                  className="span7  popovers"
                  type="number"
   value={gstamount}
                  name="customer_no"
                  id="customer_no"
                 disabled

                />
              </div>
            </div>
            <div className="control-group d-flex flex-wrap">
            <label className="control-label">
                Total Pending Amount
              </label>
                <input type="number" id="basic_amount"
                   className="span7  popovers"
                 name="basic_amount"
                 value={pending}
                 disabled/>

                <p className=" mt-4">Not Understand Call us </p>
            </div>
            <div className="control-group d-flex flex-wrap">
            <label className="control-label">
                Paid Amount</label>
              <div className="controls ">
                <input
                  type="number"
                  name="tax_amount"
                  id="tax_amount"
                  className="span6  popovers"
                  value={data.paidamount}
                  onChange={handlechangepaidamount}
                />
              </div>
              <p className=" mt-4">Enter Same Amount Showing in Pending Amount :{pending}</p>
            </div>

            <div className="control-group">
            <label className="control-label">
            Amount in words<a className="blink">*</a>
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span7  popovers"
                  name="amountinwords"
                  id="total_bill_amount"
                  disabled
                  value={data.amountinwords}
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Basic Amount
              </label>
              <div className="controls">
                <input
                  type="number"
                   className="span7  popovers"
                  name="basicamount"
                  id="paid_amount"
                  disabled
                  value={data.basicamount}
                 />
              </div>
            </div>



            <div className="control-group">
            <label className="control-label">
                Tax Amount
              </label>
              <div className="controls">
                <input
                  type="number"
                  name="taxamount"
                  id="balance_amount"
                   className="span7  popovers"
                  disabled
                  value={data.taxamount}
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Balance Amount
              </label>
              <div className="controls">
                <input
                  type="number"
                  name="balanceamount"
                  id="discount_amount"
                 className="span7  popovers"
                disabled
                value={data.balanceamount}
                />
              </div>
            </div>
            <div className="createcancel_btn">
              <button
              type="submit"
              className="btn-data"
              onClick={handlesubmit}>
                Paid
              </button>
              <button type="reset" className="cancel-btn btn">Cancel</button>
            </div>
            </div>
          </form>
          )}
      </div>

      <div className="row bg-body-tertiary mt-4">
<div className="dashservice">
  <p className="name-text">Pending Amount Details</p>
  <FaChevronDown onClick={()=>{setshowtable(!showtable)}}/>
</div>
 <div>
 {showtable && ( <Table  responsive className=" table table-striped  table-bordered table-advance table-hover table-responsive table-hover mt-3 mb-3 bor-dis">
    <thead className="">
      <tr>
      <th>Member Id</th>
      <th>Branch Name</th>
      <th>Deposit Date</th>
      <th>Payment Type</th>
      <th>Total Amount</th>
      <th>Paid Amount</th>
      <th>Pending Amount</th>
      <th>Deposit Amount</th>
      <th>Manager Name</th>
      <th>Status</th>
      </tr>
    </thead>
    <tbody>
     {pendingdata && pendingdata.map((ele)=>{
      return(
        <tr>
        <td>{ele.status === 1 ? <span className='active'></span> : <span className='inactive'></span>}{ele.member_id}</td>
        <td>{ele.branch_name}</td>
        <td>{formatDate(ele.currdate.date)}</td>
        <td>{ele.payment_mode}</td>
        <td>{ele.total_amount}</td>
        <td>{ele.paid_amount}</td>
        <td>{ele.bal_amount}</td>
        <td>{ele.deposit_amount}</td>
        <td>{ele.create_by}</td>
        <td><>
        <Link className='btn-click-2' target="_blank" rel="noopener noreferrer" to={`/invoice/${encodeURIComponent(encryptData(branchtable))}/${encodeURIComponent(encryptData(ele.user_id))}`} >View Invoice</Link>
          </></td>
        </tr>
      )
     })}
    </tbody>
  </Table>)}
 </div>
      </div>
      </div>
      }
      </div>
);
};

export default PaidAccount;